// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font-Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Datatable
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
@import '~datatables.net-select-bs4/css/select.bootstrap4.min.css';
@import '~datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css';
@import '~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';
@import '~datatables.net-searchbuilder-bs4/css/searchBuilder.bootstrap4.min.css';
@import '~datatables.net-searchpanes-bs4/css/searchPanes.bootstrap4.min.css';

// Flatpickr
@import "flatpickr/dist/flatpickr.min.css";

.fs-7 {
    font-size: 0.85rem !important;
}

.dropdown-toggle.no-arrow::after {
    content: none;
}

.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
}
.btn-circle.btn-md {
    width: 50px;
    height: 50px;
    padding: 7px 10px;
    border-radius: 25px;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
}
